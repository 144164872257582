import { publicDownloadFile, publicRequest } from '../config/axiosConfig';
import { createFormData } from '../utils/commons';
import FileDownload from 'js-file-download';

export const createProduct = async (name, brandName, description, category, catalog, files, productItems, stockId) => {
  try {
    const query = createFormData({
      name,
      brandName,
      description,
      category,
      catalog,
      files,
      productItems,
      stockId,
    });
    const response = await publicRequest.post(`/products`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.log('Error', error);
    throw new Error(error.response.data.message);
  }
};

export const getProductListItems = async () => {
  try {
    const response = await publicRequest.get(`/products`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const downloadFile = async (filename, fileOriginalName) => {
  try {
    const response = await publicDownloadFile.get(`/files/single/${filename}`);
    FileDownload(response.data, fileOriginalName);
    console.log(response.data);
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
};
