import axios from 'axios';

export const BASE_URL = 'http://localhost:8085/api';
// export const BASE_URL = 'https://e-commerce-light-backend.onrender.com/api';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const publicDownloadFile = axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
});

export const authenticatedRequest = token =>
  axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
