import { IconHanger2, IconMotorbike, IconReceipt2, IconShoppingBag, IconShoppingCart } from '@tabler/icons-react';
import Products from '../pages/Products';
import Stocks from '../pages/Stocks';
import CustomerOrders from '../pages/Orders';
import Riders from '../pages/Riders';
import CreateProduct from '../pages/Products/CreateProduct';
import StockDetails from '../pages/Stocks/pages/StockDetails';
import Expenses from '../pages/Expenses';
import OrderDetails from '../pages/Orders/pages/OrderDetails';

const routes = [
  // {
  //   type: 'collapse',
  //   name: 'Dashboard',
  //   key: 'dashboard',
  //   icon: IconLayoutDashboard,
  //   route: '/dashboard',
  //   roles: ['USER', 'ADMIN'],
  //   isProtected: true,
  //   component: <Dashboard />,
  // },
  {
    type: 'collapse',
    name: 'Produits',
    key: 'products',
    icon: IconHanger2,
    route: '/products',
    roles: ['USER', 'ADMIN'],
    isProtected: true,
    component: <Products />,
    children: [
      {
        type: 'collapse',
        name: 'Produits',
        key: 'products',
        icon: IconHanger2,
        route: '/products/:id/new',
        roles: ['USER', 'ADMIN'],
        isProtected: true,
        component: <CreateProduct />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Stocks',
    key: 'stocks',
    icon: IconShoppingBag,
    route: '/stocks',
    roles: ['USER', 'ADMIN'],
    isProtected: true,
    component: <Stocks />,
    children: [
      {
        type: 'collapse',
        name: 'Stock',
        key: 'stocks',
        route: '/stocks/:id',
        roles: ['USER', 'ADMIN'],
        isProtected: true,
        component: <StockDetails />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Commandes',
    key: 'customer-orders',
    icon: IconShoppingCart,
    route: '/customer-orders',
    roles: ['USER', 'ADMIN'],
    isProtected: true,
    component: <CustomerOrders />,
    children: [
      {
        type: 'collapse',
        name: 'Détails commande',
        key: 'customer-orders',
        icon: IconReceipt2,
        route: '/customer-orders/:orderId',
        roles: ['USER', 'ADMIN'],
        isProtected: true,
        component: <OrderDetails />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Dépenses',
    key: 'expenses',
    icon: IconReceipt2,
    route: '/expenses',
    roles: ['USER', 'ADMIN'],
    isProtected: true,
    component: <Expenses />,
  },
  {
    type: 'collapse',
    name: 'Mes livreurs',
    key: 'riders',
    icon: IconMotorbike,
    route: '/riders',
    roles: ['USER', 'ADMIN'],
    isProtected: true,
    component: <Riders />,
  },
];

export default routes;
