import { authenticatedRequest, publicRequest } from '../config/axiosConfig';

export const getRiders = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/riders`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getRidersForTable = async () => {
  try {
    const response = await publicRequest.get(`/riders/table-list`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createRider = async rider => {
  try {
    const { email, ...others } = rider;
    const riderToServer = email ? rider : others;
    const response = await publicRequest.post(`/riders`, riderToServer);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error.response.data);
  }
};
