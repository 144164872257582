import {
  Box,
  Card,
  Checkbox,
  Grid,
  Group,
  Indicator,
  MultiSelect,
  NumberInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { QuantityInput } from '../../../../components/QuantityInput';
import { useEffect, useState } from 'react';
import { IconTrash } from '@tabler/icons-react';
import { REQUIRED_ERROR } from '../../../../utils/constants';

const ProductItem = ({ productItem, sizes, colors, index, onChange, removeProductItem, isSubmit }) => {
  // Validators
  const [purchasePriceErrorText, setPurchasePriceErrorText] = useState('');
  const [sellingPriceErrorText, setSellingPriceErrorText] = useState('');
  const [sizeErrorText, setSizeErrorText] = useState('');
  const [colorErrorText, setColorErrorText] = useState('');
  const [targetAudiencesErrorText, setTargetAudiencesErrorText] = useState('');

  const isPriceValid = (price, setPriceError) => {
    if (price > 0) {
      setPriceError('');
      return true;
    }
    setPriceError(REQUIRED_ERROR);
    return false;
  };

  const isSizeOrColorValid = (value, setValueError) => {
    if (value) {
      setValueError('');
      return true;
    }
    setValueError(REQUIRED_ERROR);
    return false;
  };

  const isTargetAudiencesValid = (array, setArrayError) => {
    if (array.length !== 0) {
      setTargetAudiencesErrorText('');
      return true;
    }
    setTargetAudiencesErrorText(REQUIRED_ERROR);
    return false;
  };

  useEffect(() => {
    if (isSubmit) {
      isPriceValid(productItem.purchasePrice, setPurchasePriceErrorText);
      isPriceValid(productItem.sellingPrice, setSellingPriceErrorText);
      isSizeOrColorValid(productItem.color, setColorErrorText);
      isSizeOrColorValid(productItem.size, setSizeErrorText);
      isTargetAudiencesValid(productItem.targetAudiences, setSizeErrorText);
    }
  }, [isSubmit]);

  const handleChange = (index, value, attribute) => {
    onChange(index, { ...productItem, [attribute]: value });
    switch (attribute) {
      case 'color':
        setColorErrorText('');
        break;
      case 'size':
        setSizeErrorText('');
        break;
      case 'purchasePrice':
        setPurchasePriceErrorText('');
        break;
      case 'sellingPrice':
        setSellingPriceErrorText('');
        break;
      case 'targetAudiences':
        setTargetAudiencesErrorText('');
        break;
      default:
        return;
    }
  };

  const setQuantity = quantity => {
    handleChange(index, quantity, 'quantity');
  };

  return (
    <Box mb="md">
      <Indicator
        color="transparent"
        label={
          index !== 0 && <IconTrash color="red" sx={{ cursor: 'pointer' }} onClick={() => removeProductItem(index)} />
        }
      >
        <Box p="sm" sx={{ border: '1px dashed #E5E4E2', borderRadius: '5px' }}>
          <Grid>
            <Grid.Col xs={6}>
              <Select
                data={sizes}
                label="Taille"
                placeholder="xs"
                maxDropdownHeight={160}
                searchable
                withinPortal
                withAsterisk
                nothingFound="Rien trouvé"
                transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
                value={productItem.size}
                onChange={value => handleChange(index, value, 'size')}
                error={sizeErrorText}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                data={colors}
                label="Couleur"
                placeholder="rouge"
                maxDropdownHeight={160}
                withinPortal
                withAsterisk
                searchable
                nothingFound="Rien trouvé"
                transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
                value={productItem.color}
                onChange={value => handleChange(index, value, 'color')}
                error={colorErrorText}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={6}>
              <NumberInput
                label="Prix d'achat"
                placeholder="10000"
                withAsterisk
                rightSection={
                  <Text color="dimmed" size="sm">
                    FG
                  </Text>
                }
                value={productItem.purchasePrice}
                onChange={value => handleChange(index, value, 'purchasePrice')}
                error={purchasePriceErrorText}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                label="Prix de vente"
                placeholder="12000"
                withAsterisk
                rightSection={
                  <Text color="dimmed" size="sm">
                    FG
                  </Text>
                }
                value={productItem.sellingPrice}
                onChange={value => handleChange(index, value, 'sellingPrice')}
                error={sellingPriceErrorText}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={6}>
              <Text size="sm">Quantité</Text>
              <QuantityInput min={1} max={100} quantity={productItem.quantity} setQuantity={setQuantity} />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Checkbox.Group
                label="Genre"
                withAsterisk
                value={productItem.targetAudiences}
                onChange={value => handleChange(index, value, 'targetAudiences')}
                error={targetAudiencesErrorText}
              >
                <Group mt="xs">
                  <Checkbox value="Men" label="Home" />
                  <Checkbox value="Women" label="Femme" />
                  <Checkbox value="Kids" label="Enfant" />
                </Group>
              </Checkbox.Group>
            </Grid.Col>
          </Grid>
        </Box>
      </Indicator>
    </Box>
  );
};

export default ProductItem;
