import { createSlice } from '@reduxjs/toolkit';

const OrderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
  },
  reducers: {
    addOrder: (state, { payload }) => {
      state.orders = payload;
    },

    removeOrder: (state, { payload }) => {
      state.orders = payload;
    },
  },
});

export const { addOrder, removeOrder } = OrderSlice.actions;
export default OrderSlice.reducer;
