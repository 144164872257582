import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { productColumns } from './productColumns';
import { useEffect, useState } from 'react';
import { getProductListItems } from '../../services/product.service';

const Products = () => {
  const navigate = useNavigate();

  const columns = productColumns;

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const products = await getProductListItems();
        setTableData(products);
        console.log('products', products);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProduct();
  }, []);

  const handleDeleteRow = () => {};
  const handleEditRow = () => {};

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Produits</Text>
        {/*<Button variant="filled" leftIcon={<IconPlus />} onClick={() => navigate('/products/new')}>*/}
        {/*  Ajouter un produit*/}
        {/*</Button>*/}
      </Flex>

      <Box p={10}>
        {columns && columns.length > 0 && (
          <DataTable
            tableColumns={productColumns}
            tableData={tableData}
            isLoading={isLoading}
            isError={false}
            enableActions={false}
            enableExpanding={false}
            showColumnFilters={false}
          />
        )}
      </Box>
    </AppShell>
  );
};

export default Products;
