import { ActionIcon, Burger, Container, Group, Header, MediaQuery, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun, IconUserCircle } from '@tabler/icons-react';
import { useDashboardNavbarStyles } from './style';
import { useDispatch } from 'react-redux';
import { darkModeReducer } from '../../../redux/settings.slice';

export function DashboardNavbar({ opened, toggle }) {
  const dispatch = useDispatch();

  const { colorScheme } = useMantineColorScheme();
  const { classes } = useDashboardNavbarStyles();

  const handleColor = theme => {
    return {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    };
  };

  const handleDarkMode = () => {
    dispatch(darkModeReducer());
  };

  return (
    <Header height={64} mb={20}>
      <Container fluid className={classes.inner}>
        <MediaQuery styles={{ display: 'none' }} largerThan={1200}>
          <Burger opened={opened} onClick={() => toggle()} size="sm" className={classes.burger} />
        </MediaQuery>

        <Group spacing={5} className={classes.social} position="right" noWrap>
          <ActionIcon size="lg" sx={handleColor}>
            <IconUserCircle size="1.3rem" />
          </ActionIcon>
          <ActionIcon onClick={handleDarkMode} size="lg" sx={handleColor}>
            {colorScheme === 'dark' ? <IconSun size="1.3rem" /> : <IconMoonStars size="1.3rem" />}
          </ActionIcon>
        </Group>
      </Container>
    </Header>
  );
}
