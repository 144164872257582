import React, { useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import PropTypes from 'prop-types';
import { Text } from '@mantine/core';

const propTypes = {
  tableColumns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};

const DataTable = ({
  tableColumns,
  tableData,
  isLoading,
  isError,
  enableActions,
  enableExpanding,
  showColumnFilters,
  renderRowActionMenuItems,
  renderDetailPanel,
}) => {
  //should be memoized or stable
  const columns = useMemo(() => tableColumns, []);

  const table = useMantineReactTable({
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        mantineTableHeadCellProps: {
          align: 'center',
        },
        size: 120,
      },
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    initialState: { columnVisibility: { id: false }, density: 'xs' }, //hide firstName column by default
    columns: columns,
    data: tableData,
    enableRowActions: enableActions,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    enableColumnResizing: true,
    enableFullScreenToggle: false,
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showColumnFilters: showColumnFilters,
    },
    renderEmptyRowsFallback: () => <Text>Aucune donnée trouvée 😳</Text>,
    enableExpanding: enableExpanding,
    ...(enableExpanding ? { renderDetailPanel } : {}),
    renderRowActionMenuItems: renderRowActionMenuItems,
  });

  return <MantineReactTable table={table} />;
};

DataTable.propTypes = propTypes;

export default DataTable;
