export const CRUD_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const PROJECT_STATUS = {
  WAITING: 'EN ATTENTE',
  ONGOING: 'EN COURS',
  CANCELLED: 'ANNULER',
  FINISHED: 'TERMINER',
};

export const OrderStatus = ['ENCOURS', 'CREDIT', 'PAYE', 'ANNULE'];

export const OrderStatusMap = {
  ENCOURS: {
    id: 1,
    name: 'ENCOURS',
    color: 'orange',
    availables: [2, 3, 4],
    value: 'En cours', // Added "value" property
  },
  CREDIT: {
    id: 2,
    name: 'CREDIT',
    color: 'orange',
    availables: [3],
    value: 'Crédit', // Added "value" property
  },
  PAYE: {
    id: 3,
    name: 'PAYE',
    color: 'green',
    availables: [],
    value: 'Payé', // Added "value" property
  },
  ANNULE: {
    id: 4,
    name: 'ANNULE',
    color: 'red',
    availables: [],
    value: 'Annulé', // Added "value" property
  },
};

export const PROJECT_STATUS_WAITING_OPTIONS = [{ action: 'Démarer projet', status: 'EN COURS' }];

export const PROJECT_STATUS_STARTED_OPTIONS = [
  { action: 'Annuler projet', status: 'ANNULER' },
  { action: 'Finir projet', status: 'TERMINER' },
];

// FORM
export const EMAIL_PATTERN = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const GUINEA_PHONE_NUMBER_PATTERN = /^\d{9}$/;
export const PHONE_NUMBER_PATTERN =
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;

// FORM ERROR MESSAGES
export const REQUIRED_ERROR = 'Obligatoire';
export const PRICE_ERROR = 'Prix inférieur ou égale à 0';
export const INCORRECT_VALUE_ERROR = 'Obligatoire';
export const INVALID_EMAIL_ERROR = 'Email invalide';
export const INVALID_PHONE_ERROR = 'Téléphone invalide';
export const INVALID_DATE_ERROR = 'Date invalide';
export const END_DATE_INFERIOR_START_DATE_ERROR = 'Date de fin inférieur à la date de début';
export const INVALID_PHONE_NUMBER_ERROR = 'Téléphone invalide';
export const NAME_MIN_LENGTH_ERROR = 'Longueur inférieur à 3';
export const TEXTAREA_MIN_LENGTH_ERROR = 'Longueur inférieur à 20';
export const TEXTAREA_MAX_LENGTH_ERROR = 'Longueur inférieur à 1000';
export const NAME_MAX_LENGTH_ERROR = 'Longueur supérieur à 30';
export const CONFIRM_PASSWORD_ERROR = 'Mot de passe non conforme';
export const INVALID_NUMBER_ERROR = 'Nombre incorrect';
export const LEVEL_MIN_PERCENTAGE_ERROR = 'Pourcentage inférieur à 10';
export const ARRAY_NOT_EMPTY_ERROR = 'Aucun métier choisi';
