import {ActionIcon, Anchor, Container, Header, useMantineColorScheme} from '@mantine/core';
import {IconMoonStars, IconSun} from '@tabler/icons-react';
import {useDefaultNavbarStyles} from './style';
import {darkModeReducer} from '../../../redux/settings.slice';
import {useDispatch} from 'react-redux';


export function DefaultNavbar() {
  const dispatch = useDispatch();
  const { colorScheme } = useMantineColorScheme();
  const { classes } = useDefaultNavbarStyles();

  const handleColor = (theme) => {
    return {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    }
  }

  const handleDarkMode = () => {
    dispatch(darkModeReducer());
    //toggleColorScheme();
  }

  return (
    <Header
      height={64}
      mb={20}
      fixed={true}
    >
      <Container fluid className={classes.inner}>
        <Anchor href="/home" className={classes.title}>
          Pivot
        </Anchor>

        <ActionIcon
          onClick={handleDarkMode}
          size="lg"
          sx={handleColor}
        >
          {colorScheme === 'dark' ? <IconSun size="1.3rem" /> : <IconMoonStars size="1.3rem" />}
        </ActionIcon>
      </Container>
    </Header>
  );
}