import {createStyles, rem} from '@mantine/core';

export const useDefaultNavbarStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(63),
    width: '100%',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingLeft: rem(30),
    paddingRight: rem(20),
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(30),
    lineHeight: 1.2,
    fontWeight: 900,
    cursor: 'pointer',

    '&:visited, &:hover': {
      textDecoration: 'none'
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(20),
    },
  },
}));