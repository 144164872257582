import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import DataTable from '../../components/DataTable';
import { riderColumns } from './riderColumns';
import { createRider, getRidersForTable } from '../../services/rider.service';
import CreateRider from './pages/CreateRider';
import { renderCRUDRequestNotification } from '../../utils/commons';
import { CRUD_ACTION } from '../../utils/constants';

const Riders = () => {
  const [isLoading, { open, close }] = useDisclosure(true);
  const [openRiderModal, handleRiderModal] = useDisclosure(false);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchRiders = async () => {
      try {
        const riders = await getRidersForTable();
        setTableData(riders);
      } catch (error) {
        console.error(error);
      }
      close();
    };
    fetchRiders();
  }, []);

  const submitRider = async values => {
    open();
    const riderName = `${values.firstname} ${values.lastname}`;
    try {
      const riders = await createRider(values);
      setTableData(riders);
      renderCRUDRequestNotification('livreur', riderName, CRUD_ACTION.CREATE, 'success');
    } catch (error) {
      console.error(error);
      renderCRUDRequestNotification('livreur', riderName, CRUD_ACTION.CREATE, 'error');
    }
    close();
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Livreurs</Text>
        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleRiderModal.open()}>
          Ajouter un livreur
        </Button>
      </Flex>

      <Box p={10}>
        <DataTable
          tableColumns={riderColumns}
          tableData={tableData}
          isLoading={isLoading}
          isError={false}
          enableActions={false}
          enableExpanding={false}
          showColumnFilters={false}
        />
      </Box>

      <CreateRider opened={openRiderModal} closeModal={handleRiderModal.close} submit={submitRider} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Riders;
