import {publicRequest} from '../config/axiosConfig';

export const register = async (user) => {
  try {
    const response = await publicRequest.post(`/users/auth/signup`, user);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
}

export const login = async (email, password) => {
  try {
    const response = await publicRequest.post(`/users/auth/signin`, {email, password});
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
}