import AppShell from '../../layouts/AppShell';
import { Button, Container, Flex, Grid, Group, LoadingOverlay, rem, Text, Title } from '@mantine/core';
import { IconBasketPlus, IconPlus } from '@tabler/icons-react';
import CreateStock from './pages/CreateStock';
import { useDisclosure } from '@mantine/hooks';
import { createStock, getStocks } from '../../services/stock.service';
import { renderCRUDRequestNotification } from '../../utils/commons';
import { CRUD_ACTION } from '../../utils/constants';
import { useEffect, useState } from 'react';
import PersonalOrderCard from './components/PersonalOrderCard';
import { usePersonalOrdersStyles } from './style';
import { useNavigate } from 'react-router-dom';
import CreateExpense from '../Expenses/pages/CreateExpense';

const Stocks = () => {
  const { classes } = usePersonalOrdersStyles();

  const navigate = useNavigate();

  const [openStockModal, handleStockModal] = useDisclosure(false);
  const [isLoading, { open, close }] = useDisclosure(false);

  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    const fetchPersonalOrders = async () => {
      try {
        const stocks = await getStocks();
        setStocks(stocks);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPersonalOrders();
  }, []);

  const submitStock = async values => {
    open();
    try {
      const personalOrder = await createStock(values.name);
      setStocks([...stocks, personalOrder]);
      renderCRUDRequestNotification('stock', values.name, CRUD_ACTION.CREATE, 'success');
    } catch (error) {
      console.error(error);
      renderCRUDRequestNotification('stock', values.name, CRUD_ACTION.CREATE, 'error');
    }
    close();
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Stocks</Text>
        <Group position="apart">
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleStockModal.open()}>
            Ajouter un stock
          </Button>
        </Group>
      </Flex>

      {/* Projects */}
      {stocks.length === 0 && (
        <Container className={classes.root}>
          <div className={classes.label}>
            <IconBasketPlus size={rem(180)} />
          </div>
          <Title className={classes.title}>Vous n'avez pas encore de stock</Title>
          <Group position="center">
            <Button variant="subtle" size="md" onClick={() => handleStockModal.open()}>
              Ajouter une nouveau stock
            </Button>
          </Group>
        </Container>
      )}
      <Grid gutter="lg" align="stretch" p={10}>
        {stocks &&
          stocks?.map(({ _id, name, reference, createdAt }) => (
            <Grid.Col md={6} lg={4} onClick={() => navigate(`/stocks/${_id}`)} key={_id}>
              <PersonalOrderCard name={name} reference={reference} createdAt={createdAt} />
            </Grid.Col>
          ))}
      </Grid>

      <CreateStock opened={openStockModal} closeModal={handleStockModal.close} submit={submitStock} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Stocks;
