import {
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  ScrollArea,
  Select,
  Stack,
  Text,
  Timeline,
} from '@mantine/core';
import AppShell from '../../../../layouts/AppShell';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  cancelOrder,
  finalizeOrder,
  getOrderById,
  payOrderCredit,
  updateCustomerAddress,
} from '../../../../services/order.service';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../../../config/axiosConfig';
import { formatCurrency, renderNotification } from '../../../../utils/commons';
import { OrderStatusMap } from '../../../../utils/constants';
import { IconChevronDown, IconSun } from '@tabler/icons-react';
import Address from '../../layouts/Address';
import { getRiders } from '../../../../services/rider.service';
import Delivery from '../../layouts/Delivery';
import { modals } from '@mantine/modals';
import { dateTimeToFrenchFormat } from '../../../../utils/date-convert';
import { removeOrder } from '../../../../redux/order.slice';
import ProductItemLine from '../../components/ProductItemLine';

const OrderDetails = () => {
  const { orderId } = useParams();
  const { token } = useSelector(state => state.user);
  const { orders } = useSelector(state => state.orders);

  const dispatch = useDispatch();

  const [isLoading, { open, close }] = useDisclosure(true);
  const [editAddress, setEditAddress] = useState(false);
  const [order, setOrder] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [riders, setRiders] = useState([]);

  const [headerDirection, setHeaderDirection] = useState('row');

  const matches = useMediaQuery('(min-width: 800px)');

  useEffect(() => {
    if (orderId) {
      const fetchOrder = async () => {
        try {
          const order = await getOrderById(token, orderId);
          initOrder(order);
        } catch (error) {
          console.error(error);
        }
        close();
      };

      const fetchRiders = async () => {
        try {
          const response = await getRiders(token);
          const riders = response.map(rider => ({
            ...rider,
            value: rider.phone,
            label: rider.firstname + ' ' + rider.lastname,
          }));
          setRiders(riders);
        } catch (error) {
          console.error(error);
        }
      };

      fetchOrder();
      fetchRiders();
    }
  }, [orderId]);

  const initOrder = order => {
    setOrder(order);
    setTotalPrice(calculateBasketTotalPrice(order.orderItems) + order.deliveryFee);
    setDeliveryFee(order.deliveryFee);
  };

  useEffect(() => {
    if (matches) {
      setHeaderDirection('row');
    } else {
      setHeaderDirection('column');
    }
  }, [matches]);

  const getOrderStatus = currentStatus => {
    const statusAvailableIds = OrderStatusMap[currentStatus].availables;

    const filteredStatusMap = Object.keys(OrderStatusMap)
      .filter(key => statusAvailableIds.includes(OrderStatusMap[key].id))
      .map(key => ({
        value: OrderStatusMap[key].name,
        label: OrderStatusMap[key].value,
      }));

    filteredStatusMap.unshift({
      value: OrderStatusMap[currentStatus].name,
      label: OrderStatusMap[currentStatus].value,
    });
    return filteredStatusMap;
  };

  const handleSelectChange = async currentStatus => {
    if (currentStatus === order.status) {
      return;
    }
    if (currentStatus === 'ANNULE') {
      onOrderCancel(currentStatus);
    } else {
      // Pay order credit
      if (currentStatus === 'PAYE' && order.status === 'CREDIT') {
        await payCredit(currentStatus);
        return;
      }

      // Pay order directly or make credit
      await handlePayOrCreditOrder(currentStatus);
    }
  };

  const onOrderCancel = currentStatus => {
    modals.openConfirmModal({
      modalId: currentStatus,
      title: (
        <div>
          <Text align="center">Etes-vous sûr de vouloir annuler cette commandes ?</Text>
          <Text align="center">Une fois annuler vous ne pouvez plus modifier la commande</Text>
        </div>
      ),
      labels: { confirm: 'Annuler commande', cancel: 'Revenir en arrière' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await confirmCancelOrder(currentStatus);
      },
    });
  };

  const handlePayOrCreditOrder = async currentStatus => {
    const currentOrderSlice = orders.find(order => order._id === orderId);
    const orderFinalizationObject = {
      orderId,
      status: currentStatus,
    };
    if (!currentOrderSlice) {
      orderFinalizationObject.methodOfDelivery = 'pickUp';
    } else {
      if (currentOrderSlice.methodOfDelivery !== 'pickUp' && currentOrderSlice.rider === null) {
        renderNotification('Vous devez ajouter un livreur', 'error');
        return;
      }
      orderFinalizationObject.methodOfDelivery = currentOrderSlice.methodOfDelivery;
      if (currentOrderSlice.methodOfDelivery !== 'pickUp') {
        orderFinalizationObject.riderId = currentOrderSlice.rider._id;
      }
    }

    // Pay or credit order
    await payOrCreditOrder(orderFinalizationObject);
  };

  const payCredit = async currentStatus => {
    open();
    try {
      const response = await payOrderCredit(token, orderId);
      renderNotification(response.message, 'success');
      order.status = currentStatus;
      setOrder({ ...order });
    } catch (error) {
      console.error(error.message);
      renderNotification(error.message, 'error');
    }
    close();
  };

  const payOrCreditOrder = async orderFinalizationObject => {
    open();
    try {
      const response = await finalizeOrder(token, orderFinalizationObject);
      setOrder(response.order);
      dispatch(removeOrder(orders.filter(order => order.orderId !== orderId)));
      renderNotification(response.message, 'success');
    } catch (error) {
      console.error(error);
      renderNotification(error.message, 'error');
    }
    close();
  };

  const confirmCancelOrder = async currentStatus => {
    open();
    try {
      const response = await cancelOrder(token, orderId);
      renderNotification(response.message, 'success');
      order.status = currentStatus;
      setOrder({ ...order });
    } catch (error) {
      console.error(error.message);
      renderNotification(error.message, 'error');
    }
    close();
  };

  const calculateBasketTotalPrice = orderItems => {
    return orderItems.reduce((sum, orderItem) => sum + orderItem.productItem.sellingPrice * orderItem.quantity, 0);
  };

  const submitAddress = async address => {
    open();
    try {
      const newAddress = await updateCustomerAddress(token, address, orderId);
      order.address = newAddress;
      setOrder({ ...order });
      setEditAddress(false);
      renderNotification("L'adress a été mis à jour avec succes", 'success');
    } catch (error) {
      console.error(error);
      renderNotification("La mis à jour de l'address a échoué", 'error');
    }
    close();
  };

  return (
    <AppShell>
      {order && (
        <>
          <Flex justify="space-between" direction={headerDirection} p={5}>
            <Group>
              <Text className="pageTitle" align="center">
                Commande
              </Text>
              <Badge ml="xs" variant="filled" color={OrderStatusMap[order.status].color}>
                {OrderStatusMap[order.status].value}
              </Badge>
            </Group>
            <Select
              data={getOrderStatus(order.status)}
              value={order.status}
              onChange={handleSelectChange}
              radius="md"
              sx={{ width: 120 }}
              rightSection={<IconChevronDown />}
              disabled={order.status === 'ANNULE' || order.status === 'PAYE'}
            />
          </Flex>

          <Grid mt="xl">
            <Grid.Col xs={8}>
              <Card radius={10} shadow="md">
                <ScrollArea miw="100%" scrollbarSize={2}>
                  {order.orderItems.map((orderItem, index) => (
                    <ProductItemLine
                      key={orderItem.productItem._id}
                      productItem={orderItem.productItem}
                      quantity={orderItem.quantity}
                      indexInArray={index}
                      minWidth={600}
                    />
                  ))}
                </ScrollArea>
                <Divider mt="xl" mb="xl" size="md" />
                <Grid justify="flex-end">
                  <Grid.Col xs={6}>
                    <Stack>
                      <Group position="apart" miw={250}>
                        <Text size={16} c="dimmed">
                          Frais de livraison
                        </Text>
                        <Text fw={650} size={16}>
                          {formatCurrency(deliveryFee)} FG
                        </Text>
                      </Group>
                      <Group position="apart" miw={250}>
                        <Text fw={650} size={16}>
                          Total
                        </Text>
                        <Text fw={650} size={16}>
                          {formatCurrency(totalPrice)} FG
                        </Text>
                      </Group>
                    </Stack>
                  </Grid.Col>
                </Grid>
              </Card>

              <Card radius={10} mt="xl" shadow="md">
                <Text fw={650} size={18} mb="md">
                  Client
                </Text>

                <Group spacing={0}>
                  <Avatar radius="xl" mr={10} />
                  <Box>
                    <Text>
                      {order.customer.firstname} {order.customer.lastname}
                    </Text>
                    <Text color="dimmed">{order.customer.phone}</Text>
                  </Box>
                </Group>

                <Divider variant="dashed" mt="md" mb="md" />

                <Address
                  address={order.address}
                  editAddress={editAddress}
                  setEditAddress={setEditAddress}
                  submit={submitAddress}
                  canEdit={order.status === 'ENCOURS'}
                />

                <Divider variant="dashed" mt="md" mb="md" />

                <Delivery
                  riders={riders}
                  orderId={orderId}
                  setDeliveryFee={setDeliveryFee}
                  canEdit={order.status === 'ENCOURS'}
                />
              </Card>
            </Grid.Col>
            <Grid.Col xs={4}>
              <Card radius={10} shadow="md">
                <Text fw={650} size={18} mb="md">
                  Historique
                </Text>
                <Timeline bulletSize={24}>
                  {order.actions.map(action => (
                    <Timeline.Item key={action.date} title={action.title} bullet={<IconSun size="0.8rem" />}>
                      <Text c="dimmed" size="sm">
                        {dateTimeToFrenchFormat(action.date)}
                      </Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Card>
            </Grid.Col>
          </Grid>
        </>
      )}

      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default OrderDetails;
