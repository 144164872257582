import { CRUD_ACTION, PROJECT_STATUS } from './constants';
import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';

export function formatMoneyAmount(amount) {
  const amountFormatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  return amountFormatted.split(',')[0];
}

export function formatCurrency(number) {
  return new Intl.NumberFormat('fr-FR').format(number);
}

export function calculateValueByPercentage(value, percentage) {
  const result = value * (percentage / 100);
  return result.toFixed(2);
}

export function calculateExpensesTotalAmount(expenses) {
  return expenses.reduce((sum, expense) => sum + expense.amount, 0);
}

export function getColorByProjectStatus(status) {
  switch (status) {
    case PROJECT_STATUS.WAITING:
      return 'secondary';
    case PROJECT_STATUS.ONGOING:
      return 'warning';
    case PROJECT_STATUS.FINISHED:
      return 'success';
    case PROJECT_STATUS.CANCELLED:
      return 'error';
    default:
      return '';
  }
}

export function renderNotification(message, severity) {
  return notifications.show({
    message: <Text dangerouslySetInnerHTML={{ __html: message }}></Text>,
    color: severity === 'success' ? 'green' : 'red',
    sx: theme => ({
      backgroundColor: severity === 'success' ? theme.colors.green[1] : theme.colors.red[1],
      color: theme.white,
    }),
  });
}

export function renderCRUDRequestNotification(entityName, entryName, action, severity) {
  return renderNotification(buildMessage(entityName, entryName, action, severity), severity);
}

export function createFormData(object) {
  const formData = new FormData();
  for (const key in object) {
    if (key === 'files') {
      object[key].forEach(file => formData.append('files', file));
    } else if (key === 'productItems') {
      object[key].forEach((productItem, index) => {
        formData.append(`productItems[${index}][size]`, productItem.size);
        formData.append(`productItems[${index}][color]`, productItem.color);
        formData.append(`productItems[${index}][purchasePrice]`, productItem.purchasePrice);
        formData.append(`productItems[${index}][sellingPrice]`, productItem.sellingPrice);
        productItem.targetAudiences.forEach((targetAudience, targetAudienceIndex) => {
          formData.append(`productItems[${index}][targetAudiences][${targetAudienceIndex}]`, targetAudience);
        });
        formData.append(`productItems[${index}][initialQuantity]`, productItem.quantity);
      });
    } else {
      formData.append(key, object[key]);
    }
  }
  return formData;
}

function buildMessage(entityName, entryName, action, severity) {
  switch (action) {
    case CRUD_ACTION.CREATE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été créé avec succes !
        `;
      } else {
        return `
            Erreur lors de la création du ${entityName} <b>${entryName}</b>
        `;
      }
    case CRUD_ACTION.UPDATE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été mis à jour avec succes !
        `;
      } else {
        return `
          Echec de la mise à jour du ${entityName} <b>${entryName}</b>
        `;
      }
    case CRUD_ACTION.DELETE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été supprimé avec succes !
        `;
      } else {
        return `
          Le ${entityName} <b>${entryName}</b> n'a pas pu être supprimé
        `;
      }
    default:
      return '';
  }
}
