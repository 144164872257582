import { Card, Group, Text, useMantineTheme } from '@mantine/core';
import useCardStyles from './style';
import { IconCalendar } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../../../utils/date-convert';

function PersonalOrderCard({ name, reference, createdAt }) {
  const { classes } = useCardStyles();
  const theme = useMantineTheme();

  return (
    <Card
      withBorder
      radius="md"
      className={classes.card}
      shadow="md"
      sx={{
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      }}
    >
      <Group position="apart" mt="lg">
        <div>
          <Text fw={500}>{name}</Text>
        </div>
      </Group>

      <Text size="xs" fw={500}>
        {reference}
      </Text>

      <Card.Section className={classes.section} mt="md">
        <Group position="apart">
          <Group spacing={2} mb={-8}>
            <IconCalendar size="1.05rem" className={classes.icon} stroke={1.5} />
            <Text size="xs">Creation</Text>
          </Group>
          <Text size="xs">{getFormattedDate(createdAt)}</Text>
        </Group>
      </Card.Section>
    </Card>
  );
}

PersonalOrderCard.propTypes = {
  name: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default PersonalOrderCard;
