import {
  Anchor,
  Button,
  Container,
  Text,
  Paper,
  PasswordInput,
  TextInput,
  Title,
  Progress,
  Popover,
  LoadingOverlay,
} from '@mantine/core';
import { DefaultNavbar } from '../../../layouts/Navbar/DefaultNavbar';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import {
  CONFIRM_PASSWORD_ERROR,
  EMAIL_PATTERN,
  GUINEA_PHONE_NUMBER_PATTERN,
  INVALID_EMAIL_ERROR,
  INVALID_PHONE_ERROR,
  NAME_MAX_LENGTH_ERROR,
  NAME_MIN_LENGTH_ERROR,
  REQUIRED_ERROR,
} from '../../../utils/constants';
import { register } from '../../../services/auth.service';
import { renderNotification } from '../../../utils/commons';
import { useNavigate } from 'react-router-dom';
import PasswordRequirement from './PasswordRequirement';
import { useEffect, useState } from 'react';
import { getStrength, requirements } from './service';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';

const Register = () => {
  const navigate = useNavigate();

  const [isLoading, { open, close }] = useDisclosure(false);
  const [popoverOpened, setPopoverOpened] = useState(false);

  const { currentUser } = useSelector(state => state.user);

  const formValidator = Yup.object().shape({
    firstname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    lastname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    email: Yup.string().matches(EMAIL_PATTERN, INVALID_EMAIL_ERROR).required(REQUIRED_ERROR),
    phone: Yup.string().matches(GUINEA_PHONE_NUMBER_PATTERN, INVALID_PHONE_ERROR).required(REQUIRED_ERROR),
    password: Yup.string().required(REQUIRED_ERROR),
    confirmPassword: Yup.string()
      .required(REQUIRED_ERROR)
      .oneOf([Yup.ref('password')], CONFIRM_PASSWORD_ERROR),
  });

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validate: yupResolver(formValidator),
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/products');
    }
  }, [currentUser]);

  const handleSubmit = async values => {
    open();
    try {
      const { confirmPassword, ...user } = values;
      await register(user);
      renderNotification('Votre compte a été créé avec succes!', 'success');
      navigate('/login', { state: { from: 'register' } });
    } catch (error) {
      console.log(error);
      form.setValues({ email: '' });
      form.setValues({ password: '' });
      form.setValues({ confirmPassword: '' });
      renderNotification('Impossible de créer votre compte', 'error');
    }
    close();
  };

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.password)} />
  ));

  const strength = getStrength(form.values.password);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  return (
    <div>
      <DefaultNavbar opened={true} />
      <Container size={420} my={100}>
        <Title align="center" sx={theme => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
          Bienvenue
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Vous avez déjà un compte?{' '}
          <Anchor href="/login" size="sm">
            Se connecter
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput label="Prénom" placeholder="John" withAsterisk {...form.getInputProps('firstname')} />
            <TextInput label="Nom" placeholder="Doe" mt="md" withAsterisk {...form.getInputProps('lastname')} />
            <TextInput
              label="Téléphone"
              placeholder="620987654"
              mt="md"
              withAsterisk
              {...form.getInputProps('phone')}
            />
            <TextInput
              label="Email"
              placeholder="john.doe@pivot.com"
              mt="md"
              withAsterisk
              {...form.getInputProps('email')}
            />
            <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
              <Popover.Target>
                <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
                  <PasswordInput
                    label="Mot de passe"
                    placeholder="Votre mot de passe"
                    mt="md"
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <Progress color={color} value={strength} size={5} mb="xs" />
                <PasswordRequirement label="Includes at least 8 characters" meets={form.values.password.length > 5} />
                {checks}
              </Popover.Dropdown>
            </Popover>
            <PasswordInput
              label="Confirmer mot de passe"
              placeholder="Votre mot de passe"
              mt="md"
              withAsterisk
              {...form.getInputProps('confirmPassword')}
            />
            <Button type="submit" fullWidth mt="xl">
              Valider
            </Button>
          </form>
        </Paper>
      </Container>
      <LoadingOverlay visible={isLoading} />
    </div>
  );
};

export default Register;
