import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import CreateExpense from './pages/CreateExpense';
import { createExpense, createExpenseForStock, getExpenses } from '../../services/expense.service';
import { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { expenseTableColumns } from './expenseTableColumns';
import { calculateExpensesTotalAmount } from '../../utils/commons';

const Expenses = ({}) => {
  const [isLoading, { open, close }] = useDisclosure(true);
  const [openExpenseModal, handleExpenseModal] = useDisclosure(false);

  const [tableData, setTableData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);

  const submitExpense = async values => {
    open();
    try {
      const expenses = await createExpense(values);
      setTableData(expenses);
    } catch (error) {
      console.error(error);
    }
    close();
  };

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const expenses = await getExpenses();
        setTableData(expenses);
        setTotalAmount(calculateExpensesTotalAmount(expenses));
      } catch (error) {
        console.error(error);
      }
      close();
    };

    fetchExpenses();
  }, []);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Dépenses</Text>
        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleExpenseModal.open()}>
          Ajouter une dépense
        </Button>
      </Flex>

      <Box mt="xl">
        {totalAmount !== null && (
          <DataTable
            tableColumns={expenseTableColumns(totalAmount)}
            tableData={tableData}
            isLoading={isLoading}
            isError={false}
            enableActions={false}
            enableExpanding={false}
            showColumnFilters={false}
          />
        )}
      </Box>

      <CreateExpense opened={openExpenseModal} closeModal={handleExpenseModal.close} submit={submitExpense} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Expenses;
