import { Box, Button, Grid, Group, Text, Textarea, TextInput } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  NAME_MAX_LENGTH_ERROR,
  NAME_MIN_LENGTH_ERROR,
  REQUIRED_ERROR,
  TEXTAREA_MAX_LENGTH_ERROR,
} from '../../../../utils/constants';

const Address = ({ address, editAddress, setEditAddress, submit, canEdit }) => {
  const formValidator = Yup.object().shape({
    district: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    complementaryAddress: Yup.string()
      .min(3, NAME_MIN_LENGTH_ERROR)
      .max(100, TEXTAREA_MAX_LENGTH_ERROR)
      .required(REQUIRED_ERROR),
    city: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      district: address.district,
      complementaryAddress: address.complementaryAddress,
      city: address.city,
      country: address.country,
    },
    validate: yupResolver(formValidator),
  });

  const renderKeyValue = (key, value) => (
    <Group position="apart">
      <Text color="dimmed" size="sm">
        {key}
      </Text>
      <Text size="sm">{value}</Text>
    </Group>
  );

  const handleSubmit = values => {
    submit(values);
  };

  return (
    <>
      <Text fw={650} size={18} mb="md">
        Addresse &nbsp; &nbsp;
        {canEdit && <IconPencil sx={{ cursor: 'pointer' }} size={18} onClick={() => setEditAddress(true)} />}
      </Text>
      {!editAddress && (
        <>
          {renderKeyValue('Quartier', address.district)}
          <Group position="apart">
            <Box maw="18%">
              <Text color="dimmed" size="sm">
                Addresse
              </Text>
            </Box>
            <Box maw="70%">
              <Text size="sm">{address.complementaryAddress}</Text>
            </Box>
          </Group>
          {renderKeyValue('Ville', address.city)}
          {renderKeyValue('Pays', address.country)}
        </>
      )}
      {editAddress && (
        <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput mt="md" label="Quartier" placeholder="Lambanyi" withAsterisk {...form.getInputProps('district')} />
          <Textarea
            label="Complément d'adresse"
            placeholder="Carrefour cimetiere"
            withAsterisk
            autosize
            mt="md"
            minRows={2}
            {...form.getInputProps('complementaryAddress')}
          />
          <Grid>
            <Grid.Col xs={6}>
              <TextInput mt="md" label="Ville" placeholder="Conakry" withAsterisk {...form.getInputProps('city')} />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput mt="md" label="Pays" disabled withAsterisk {...form.getInputProps('country')} />
            </Grid.Col>

            <Group position="right" mt="md">
              <Button type="submit">Valider</Button>
              <Button onClick={() => setEditAddress(false)}>Annuler</Button>
            </Group>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Address;
