import React from 'react';
import { useClickOutside, useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { Box, Container, Drawer } from '@mantine/core';
import Sidenav from '../Sidenav';
import { DashboardNavbar } from '../Navbar/DashbordNavbar';

function AppShell({ children }) {
  const [opened, { open, close, toggle }] = useDisclosure(true);

  const matches = useMediaQuery('(min-width: 1200px)');

  const handleClickOutSideDrawer = () => {
    if (!matches) {
      close();
    }
  };
  const ref = useClickOutside(handleClickOutSideDrawer);

  useEffect(() => {
    if (matches) {
      open();
    } else {
      close();
    }
  }, [matches]);

  return (
    <Container fluid p={0}>
      <div ref={ref}>
        <Drawer
          opened={opened}
          onClose={close}
          lockScroll={false}
          closeOnClickOutside={false}
          closeOnEscape={false}
          withCloseButton={false}
          padding={0}
          withOverlay={false}
          size={300}
          m={0}
        >
          <Sidenav />
        </Drawer>
      </div>
      <Container fluid p={0} ml={opened ? 301 : 0}>
        <DashboardNavbar opened={opened} toggle={toggle} />
        <Container fluid mah="50vh">
          {children}
        </Container>
      </Container>
    </Container>
  );
}

export default AppShell;
