import { publicRequest } from '../config/axiosConfig';

export const getExpenseTypes = async () => {
  try {
    const response = await publicRequest.get(`/expense-types`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getExpenses = async () => {
  try {
    const response = await publicRequest.get(`/expenses`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createExpenseForStock = async expense => {
  try {
    const response = await publicRequest.post(`/expenses/expense-for-stock`, expense);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error.response.data);
  }
};

export const createExpense = async expense => {
  try {
    const response = await publicRequest.post(`/expenses`, expense);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error.response.data);
  }
};
