import { publicRequest } from '../config/axiosConfig';

export const getCatalogs = async () => {
  try {
    const response = await publicRequest.get(`/catalogs`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCategories = async () => {
  try {
    const response = await publicRequest.get(`/categories`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getSizes = async () => {
  try {
    const response = await publicRequest.get(`/sizes`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getColors = async () => {
  try {
    const response = await publicRequest.get(`/colors`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const adaptArrayToSelect = (array, attributeName = 'name') => {
  return array.map(item => {
    item.value = item[attributeName];
    item.label = item[attributeName];
    return item;
  });
};
