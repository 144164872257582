import { dateToFrenchLongFormat } from '../../utils/date-convert';
import { formatCurrency } from '../../utils/commons';
import { Box } from '@mantine/core';

export const expenseTableColumns = totalAmount => [
  {
    accessorKey: 'type', //access nested data with dot notation
    header: 'Type',
    size: 100,
  },
  {
    accessorKey: 'description', //access nested data with dot notation
    header: 'Description',
  },
  {
    accessorKey: 'createdAt', //access nested data with dot notation
    header: 'Date',
    size: 100,
    Cell: ({ renderedCellValue, row }) => dateToFrenchLongFormat(row.original.createdAt),
  },
  {
    accessorKey: 'stock', //access nested data with dot notation
    header: 'Source',
    size: 60,
    Footer: () => <Box>Total :</Box>,
  },
  {
    accessorKey: 'amount', //access nested data with dot notation
    header: 'Montant',
    size: 80,
    Cell: ({ renderedCellValue, row }) => formatCurrency(row.original.amount) + ' FG',
    Footer: () => <Box>{formatCurrency(totalAmount)} FG</Box>,
  },
];
