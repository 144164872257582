import { useRef } from 'react';
import { ActionIcon, NumberInput } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { useQuantityInputStyles } from './style';

export function QuantityInput({ quantity, setQuantity, min, max }) {
  const { classes } = useQuantityInputStyles();
  const handlers = useRef(null);

  return (
    <div className={classes.wrapper}>
      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current?.decrement()}
        disabled={quantity === min}
        className={classes.control}
        onMouseDown={event => event.preventDefault()}
      >
        <IconMinus size="1rem" stroke={1.5} />
      </ActionIcon>

      <NumberInput
        variant="unstyled"
        min={min}
        max={max}
        handlersRef={handlers}
        value={quantity}
        onChange={setQuantity}
        classNames={{ input: classes.input }}
      />

      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current?.increment()}
        disabled={quantity === max}
        className={classes.control}
        onMouseDown={event => event.preventDefault()}
      >
        <IconPlus size="1rem" stroke={1.5} />
      </ActionIcon>
    </div>
  );
}
