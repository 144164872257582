import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    token: "",
  },
  reducers: {
    loginAction: (state, {payload})=>{
      state.token = payload.token;
      state.currentUser = {...payload.user};
    },
    logoutAction: (state) => {
      state.currentUser = null;
      state.token = "";
    }
  }
});

export const { loginAction, logoutAction } = authSlice.actions;
export default authSlice.reducer;