import AppShell from '../../../layouts/AppShell';
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  LoadingOverlay,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useProductStyles } from '../style';
import { useEffect, useState } from 'react';
import { renderCRUDRequestNotification } from '../../../utils/commons';
import CustomDropzone from '../../../layouts/Dropzone';
import {
  adaptArrayToSelect,
  getCatalogs,
  getCategories,
  getColors,
  getSizes,
} from '../../../services/resources.service';
import { CRUD_ACTION, NAME_MAX_LENGTH_ERROR, NAME_MIN_LENGTH_ERROR, REQUIRED_ERROR } from '../../../utils/constants';
import { useDisclosure } from '@mantine/hooks';
import { createProduct } from '../../../services/product.service';
import ProductItems from '../components/productItems';

const CreateProduct = () => {
  const navigate = useNavigate();
  const { classes } = useProductStyles();
  const { id } = useParams();

  const [files, setFiles] = useState([]);

  const [isLoading, { open, close }] = useDisclosure(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [imagesMissing, setImagesMissing] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  // Product items
  const [productItems, setProductItems] = useState([
    { size: '', color: '', purchasePrice: 0, sellingPrice: 0, targetAudiences: [], quantity: 1 },
  ]);

  const formValidator = Yup.object().shape({
    name: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    brandName: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    description: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    catalog: Yup.string()
      .test('is-valid-category', 'Invalid catalog', function (value) {
        return catalogs.some(catalog => catalog.name === value);
      })
      .required(REQUIRED_ERROR),
    category: Yup.string()
      .test('is-valid-category', 'Invalid category', function (value) {
        return filteredCategories.some(category => category.name === value);
      })
      .required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      name: '',
      brandName: '',
      description: '',
      category: '',
      catalog: '',
    },
    validate: yupResolver(formValidator),
  });

  useEffect(() => {
    const fetchResources = async () => {
      const catalogs = await getCatalogs();
      const categoriesResponse = await getCategories();
      const sizes = await getSizes();
      const colors = await getColors();
      const categories = adaptArrayToSelect(categoriesResponse);
      setCatalogs(adaptArrayToSelect(catalogs));
      setSizes(adaptArrayToSelect(sizes, 'value'));
      setColors(adaptArrayToSelect(colors));
      setCategories(categories);
      setFilteredCategories([...filterCategories(categories, catalogs[0].name)]);
      form.setFieldValue('catalog', catalogs[0].name);
    };
    fetchResources();
  }, []);

  const filterCategories = (categories, currentCatalog) => {
    return categories.filter(category => category.catalog.name === currentCatalog);
  };

  const handleSubmit = async ({ name, brandName, description, category, catalog }) => {
    if (checkIfAllProductItemsAreValid()) {
      setIsSubmit(false);
      open();
      try {
        await createProduct(name, brandName, description, category, catalog, files, productItems, id);
        form.reset();
        navigate(-1);
        renderCRUDRequestNotification('produit', name, CRUD_ACTION.CREATE, 'success');
      } catch (error) {
        renderCRUDRequestNotification('produit', name, CRUD_ACTION.CREATE, 'error');
      }
      close();
    } else {
      setIsSubmit(true);
    }
  };

  const checkIfAllProductItemsAreValid = () => {
    const productItemsValid = productItems.every(productItem => validateProductItem(productItem));
    const containFiles = files.length > 0;
    if (!containFiles) {
      setImagesMissing(true);
    }
    return productItemsValid && containFiles;
  };

  const validateProductItem = ({ color, size, purchasePrice, sellingPrice, targetAudiences }) => {
    return color && size && purchasePrice > 0 && sellingPrice > 0 && targetAudiences.length > 0;
  };

  const handleCatalogChange = catalog => {
    setFilteredCategories([...filterCategories(categories, catalog)]);
    form.setValues({ catalog: catalog });
    form.setValues({ category: '' });
  };

  const addProductItem = () => {
    setProductItems([
      ...productItems,
      {
        size: '',
        color: '',
        purchasePrice: productItems[0].purchasePrice,
        sellingPrice: productItems[0].sellingPrice,
        targetAudiences: [],
        quantity: 1,
      },
    ]);
  };

  const removeProductItem = index => {
    const updatedProductItems = [...productItems];
    updatedProductItems.splice(index, 1);
    setProductItems(updatedProductItems);
  };
  const handleProductItemChange = (index, updatedProductItem) => {
    setIsSubmit(false);
    const updatedProductItems = [...productItems];
    updatedProductItems[index] = updatedProductItem;
    setProductItems(updatedProductItems);
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Nouveau produit</Text>
      </Flex>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid mt={10}>
          {/* HANDLE CATEGORY */}
          <Grid.Col xs={4}>
            <Text fw={900} size={16}>
              Catalogue
            </Text>
          </Grid.Col>
          <Grid.Col xs={8}>
            <Card withBorder radius="md" shadow="md">
              <Grid>
                <Grid.Col xs={6}>
                  <Select
                    withAsterisk
                    withinPortal
                    data={catalogs}
                    placeholder="Vêtements"
                    label="Catalogue"
                    value={form.values.catalog}
                    onChange={handleCatalogChange}
                    error={form.getInputProps('catalog').error}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <Select
                    withAsterisk
                    withinPortal
                    searchable
                    data={filteredCategories}
                    placeholder="Jeans"
                    label="Catégorie"
                    {...form.getInputProps('category')}
                  />
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
          {/* HANDLE PRODUCT DETAIL */}
          <Grid.Col xs={4}>
            <Text fw={900} size={16}>
              Détails
            </Text>
            <Text>Veuillez saisir les détails du produit</Text>
          </Grid.Col>
          <Grid.Col xs={8}>
            <Card withBorder radius="md" shadow="md">
              <TextInput label="Nom" placeholder="Veste en cuir" withAsterisk {...form.getInputProps('name')} />
              <TextInput
                label="Marque"
                placeholder="Diesel"
                withAsterisk
                mt="md"
                {...form.getInputProps('brandName')}
              />
              <Textarea
                label="Description"
                placeholder="Ajouter la description du produit ici"
                withAsterisk
                autosize
                mt="md"
                minRows={5}
                {...form.getInputProps('description')}
              />
              <Box mt="md">
                <Text size="md" m={0}>
                  Images du produit{' '}
                  <Text span inherit c="red">
                    *
                  </Text>
                </Text>
                <CustomDropzone
                  images={files}
                  setImages={setFiles}
                  imagesMissing={imagesMissing}
                  setImagesMissing={setImagesMissing}
                />
              </Box>
            </Card>
          </Grid.Col>
          <Grid.Col xs={4}>
            <Text fw={900} size={16}>
              Propriétés
            </Text>
            <Text>Veuillez saisir les propriétés du produit</Text>
          </Grid.Col>
          <Grid.Col xs={8}>
            <Card withBorder radius="md" shadow="md">
              {productItems.map((productItem, index) => (
                <ProductItems
                  key={index}
                  sizes={sizes}
                  colors={colors}
                  productItem={productItem}
                  index={index}
                  onChange={handleProductItemChange}
                  removeProductItem={removeProductItem}
                  isSubmit={isSubmit}
                />
              ))}
              <Center>
                <Button variant="filled" mt="md" onClick={addProductItem}>
                  Ajouter propriété
                </Button>
              </Center>
            </Card>
          </Grid.Col>
        </Grid>
        <Flex justify="flex-end" align="center" mt="xl" mb="xl">
          <Button type="submit" variant="filled">
            Créer produit
          </Button>
        </Flex>
      </form>
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default CreateProduct;
