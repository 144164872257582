import { useEffect, useState } from 'react';
import { Group, Image, Navbar } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useSidenavStyles } from './style';
import { useDispatch } from 'react-redux';
import image from '../../assets/logo/Logo.png';
import routes from '../../router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutAction } from '../../redux/auth.slice';

export function Sidenav() {
  const { classes, cx } = useSidenavStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState('');

  const handleLogout = event => {
    event.preventDefault();
    dispatch(logoutAction());
  };

  useEffect(() => {
    if (pathname) {
      const paths = pathname.split('/');
      setActive(paths[1]);
    }
  }, [pathname]);

  const links = routes.map(item => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.key === active })}
      href={item.route}
      key={item.key}
      onClick={event => {
        event.preventDefault();
        setActive(item.key);
        navigate(item.route);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.name}</span>
    </a>
  ));

  return (
    <Navbar width={{ sm: 300 }} p="md" h="100vh" className={classes.root}>
      <Navbar.Section grow>
        <Group className={classes.header} position="apart">
          <Image width={120} height={40} src={image} sx={classes.image} />
        </Group>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <a href="/lougin" className={classes.link} onClick={event => handleLogout(event)}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}

export default Sidenav;
