import { Box, Group, SegmentedControl, Select, Text } from '@mantine/core';
import { forwardRef, useEffect, useState } from 'react';
import { IconArrowsExchange } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from '../../../../redux/order.slice';

const SelectItem = forwardRef(({ _id, firstname, lastname, nickname, email, phone, ...others }, ref) => {
  return (
    <div ref={ref} {...others} key={_id}>
      <Group noWrap m={1} ml={10}>
        <div>
          <Text size="sm">
            {firstname} {lastname} {nickname ? `(${nickname})` : ''}
          </Text>
          <Text size="xs" opacity={0.65}>
            Téléphone : {phone}
          </Text>
        </div>
      </Group>
    </div>
  );
});

const Delivery = ({ riders, orderId, setDeliveryFee, canEdit }) => {
  const { darkMode } = useSelector(state => state.settings);
  const { orders } = useSelector(state => state.orders);
  const dispatch = useDispatch();
  const [riderPhone, setRiderPhone] = useState('');
  const [methodOfDelivery, setMethodOfDelivery] = useState('pickUp');
  const [sliceOrder, setSliceOrder] = useState({});

  const [rider, setRider] = useState(null);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const sliceOrder = orders.find(order => order._id === orderId);
      if (sliceOrder) {
        setMethodOfDelivery(sliceOrder.methodOfDelivery);
        setSliceOrder(sliceOrder);
        setRider(sliceOrder.rider);
      } else {
        const sliceOrder = {
          _id: orderId,
          methodOfDelivery: 'pickUp',
          rider: null,
        };
        setSliceOrder(sliceOrder);
      }
    } else {
      const sliceOrder = {
        _id: orderId,
        methodOfDelivery: 'pickUp',
        rider: null,
      };
      setSliceOrder(sliceOrder);
    }
  }, [orders]);

  useEffect(() => {
    if (methodOfDelivery === 'pickUp') {
      addOrderToStore({ ...sliceOrder, methodOfDelivery: 'pickUp', rider: null });
      setDeliveryFee(0);
    } else {
      addOrderToStore({ ...sliceOrder, methodOfDelivery: 'delivery' });
      setDeliveryFee(20000);
    }
  }, [methodOfDelivery]);

  const handleSelectChange = riderPhone => {
    const foundedRider = riders.find(rider => rider.phone === riderPhone);
    if (foundedRider) {
      setRider(foundedRider);
      addOrderToStore({ ...sliceOrder, rider: foundedRider });
    }
  };

  const addOrderToStore = sliceOrder => {
    const sliceOrders = [...orders];
    const index = sliceOrders.findIndex(order => order._id === sliceOrder._id);
    if (index < 0) {
      sliceOrders.push(sliceOrder);
    } else {
      sliceOrders[index] = { ...sliceOrder };
    }
    dispatch(addOrder(sliceOrders));
  };

  return (
    <>
      <Text fw={650} size={18} mb="md">
        Livraison
      </Text>
      <SegmentedControl
        w="100%"
        value={methodOfDelivery}
        onChange={setMethodOfDelivery}
        disabled={!canEdit}
        data={[
          { label: 'Sur place', value: 'pickUp' },
          { label: 'A livrer', value: 'delivery' },
        ]}
      />
      {methodOfDelivery === 'delivery' && (
        <>
          {!rider && (
            <Box>
              <Select
                mt="md"
                withAsterisk
                searchable
                allowDeselect
                clearable
                data={riders}
                maxDropdownHeight={180}
                placeholder="Taper le mail ou le surnom"
                label="Choisir un livreur"
                itemComponent={SelectItem}
                value={riderPhone}
                onChange={handleSelectChange}
                filter={(value, item) =>
                  item?.firstname?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                  item?.lastname?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                  item?.nickname?.toLowerCase().includes(value?.toLowerCase().trim())
                }
              />
            </Box>
          )}

          {rider && (
            <Box bg={darkMode ? 'dark.7' : 'gray.1'} p={10} sx={{ borderRadius: 10 }} mt="xl">
              <Group position="apart">
                <Text size="sm" fw={650}>
                  {rider.firstname} {rider.lastname}
                </Text>
                {canEdit && (
                  <Group position="apart" sx={{ cursor: 'pointer' }} onClick={() => setRider(null)}>
                    <IconArrowsExchange color="red" />
                    <Text color="red" fw={650}>
                      Changer
                    </Text>
                  </Group>
                )}
              </Group>

              {rider.nickname && (
                <Group mt="md">
                  <Text size="sm" color="dimmed">
                    Surnom :
                  </Text>
                  <Text size="sm" fw={650}>
                    {rider.nickname}
                  </Text>
                </Group>
              )}
              <Group mt="sx">
                <Text size="sm" color="dimmed">
                  Téléphone :
                </Text>
                <Text size="sm" fw={650}>
                  {rider.phone}
                </Text>
              </Group>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Delivery;
