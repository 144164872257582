import AppShell from '../../../../layouts/AppShell';
import {
  Accordion,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  rem,
  SimpleGrid,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { IconCoin, IconHanger2, IconPlus, IconReceipt2, IconShoppingBag } from '@tabler/icons-react';
import { useStocksDetailsStyles } from './style';
import StatsCard from '../../components/StatsCard';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getStockById } from '../../../../services/stock.service';
import { useDisclosure } from '@mantine/hooks';
import CreateExpense from '../../../Expenses/pages/CreateExpense';
import { createExpenseForStock } from '../../../../services/expense.service';
import DataTable from '../../../../components/DataTable';
import { expenseTableColumns } from '../../expenseTableColumns';
import { calculateExpensesTotalAmount } from '../../../../utils/commons';
import { productColumns } from '../../productColumns';
import { riderColumns } from '../../../Riders/riderColumns';

const statsData = {
  numberOfProductsInStock: {
    title: 'Stocks',
    icon: IconHanger2,
    description: "Nombre actuel d'articles en stock",
    isCurrency: false,
  },
  numberOfProductsSold: {
    title: 'Vendus',
    icon: IconShoppingBag,
    description: "Nombre actuel d'articles vendu",
    isCurrency: false,
  },
  totalExpenses: {
    title: 'Dépenses',
    icon: IconReceipt2,
    description: "Dépenses + prix d'achats des articles",
    isCurrency: true,
  },
  totalIncome: {
    title: 'Revenus',
    icon: IconCoin,
    description: 'Revenus totals générés',
    isCurrency: true,
  },
};

const StockDetails = () => {
  const { classes } = useStocksDetailsStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();

  const [openExpenseModal, handleExpenseModal] = useDisclosure(false);

  const [isLoading, { open, close }] = useDisclosure(true);
  const [stockName, setStockName] = useState('');
  const [stats, setStats] = useState('');

  const [expenseTableData, setExpenseTableData] = useState([]);
  const [productTableData, setProductTableData] = useState([]);
  const [totalExpensesAmount, setExpensesTotalAmount] = useState(null);

  const submitExpense = async values => {
    open();
    try {
      const expenseToServer = {
        ...values,
        stockId: id,
      };
      const stock = await createExpenseForStock(expenseToServer);
      setValues(stock);
    } catch (error) {
      console.error(error);
    }
    close();
  };

  useEffect(() => {
    if (id) {
      const fetchStocksDetails = async id => {
        try {
          const stock = await getStockById(id);
          setValues(stock);
        } catch (error) {
          console.error(error);
        }
        close();
      };

      fetchStocksDetails(id);
    }
  }, [id]);

  const setValues = stock => {
    setStockName(stock.name);
    setStats(renderStats(stock.stats));
    setExpenseTableData(stock.expenses);
    setProductTableData(stock.productItems);
    setExpensesTotalAmount(calculateExpensesTotalAmount(stock.expenses));
  };
  const renderStats = stats => {
    return Object.keys(stats).map(attribute => {
      const { title, icon, description, isCurrency } = statsData[attribute];
      const value = stats[attribute];

      return (
        <StatsCard
          title={title}
          Icon={icon}
          value={value}
          isCurrency={isCurrency}
          description={description}
          key={title}
        />
      );
    });
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">{stockName}</Text>
        <Group position="apart">
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => navigate(`/products/${id}/new`)}>
            Ajouter un produit
          </Button>
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleExpenseModal.open()}>
            Ajouter une dépense
          </Button>
        </Group>
      </Flex>
      <Box mt="xl">
        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: 'md', cols: 2 },
            { maxWidth: 'xs', cols: 1 },
          ]}
        >
          {stats}
        </SimpleGrid>
      </Box>

      <Accordion color="white" mt="xl" variant="separated" defaultValue="expenses" transitionDuration={1000}>
        <Accordion.Item bg={colorScheme === 'light' ? 'white' : ''} value="expenses">
          <Accordion.Control icon={<IconReceipt2 size={rem(20)} />}>
            <Text fw={650} size={rem(20)}>
              Dépenses
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {totalExpensesAmount !== null && (
              <DataTable
                tableColumns={expenseTableColumns(totalExpensesAmount)}
                tableData={expenseTableData}
                isLoading={isLoading}
                isError={false}
                enableActions={false}
                enableExpanding={false}
                showColumnFilters={false}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item bg={colorScheme === 'light' ? 'white' : ''} value="products">
          <Accordion.Control icon={<IconHanger2 size={rem(20)} />}>
            <Text fw={650} size={rem(20)}>
              Produits
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <DataTable
              tableColumns={productColumns}
              tableData={productTableData}
              isLoading={isLoading}
              isError={false}
              enableActions={false}
              enableExpanding={false}
              showColumnFilters={false}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <CreateExpense opened={openExpenseModal} closeModal={handleExpenseModal.close} submit={submitExpense} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default StockDetails;
