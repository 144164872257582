import moment from 'moment/moment';

export function getFormattedDate(date) {
  return date ? dateToFrenchFormat(date) : 'Non connue';
}

export function dateToFrenchFormat(date) {
  return moment(new Date(date)).format('DD/MM/YYYY');
}

export function dateToFrenchLongFormat(date) {
  return moment(new Date(date)).format('DD MMMM YYYY');
}

export function dateTimeToFrenchFormat(date) {
  return moment(new Date(date)).format('DD/MM/YYYY hh:mm:ss');
}

export function dateTimeToFrenchLongFormat(date) {
  return moment(new Date(date)).format('DD MMMM YYYY hh:mm:ss');
}

export function timeFromDate(date) {
  return moment(new Date(date)).format('hh:mm:ss');
}

export function addOneDayToDate(date) {
  const newDate = moment(new Date(date)).add(1, 'd');
  return newDate.format();
}

export function checkNextGreaterThanBefore(before, next) {
  return moment(next).isAfter(before);
}

export function dateToMonthYear(date) {
  return moment(date).format('MMMM YYYY');
}

export function numberOfDaysBetweenTwoDates(date1, date2) {
  const a = moment(date1);
  const b = moment(date2);
  return b.diff(a, 'days') + 1;
}
