import AppShell from '../../layouts/AppShell';
import { Box, Card, Divider, Flex, Group, Image, Menu, Stack, Text } from '@mantine/core';
import DataTable from '../../components/DataTable';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { getOrders } from '../../services/order.service';
import { orderColumns } from './orderColumns';
import { IconFileDescription } from '@tabler/icons-react';
import { OrderStatusMap } from '../../utils/constants';
import { BASE_URL } from '../../config/axiosConfig';
import { formatCurrency } from '../../utils/commons';
import { useNavigate } from 'react-router-dom';
import ProductItemLine from './components/ProductItemLine';

const CustomerOrders = () => {
  const { token } = useSelector(state => state.user);
  const navigate = useNavigate();

  const [isLoading, { open, close }] = useDisclosure(true);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orders = await getOrders(token);
        setTableData(orders);
      } catch (error) {
        console.log(error);
      }
      close();
    };
    fetchOrders();
  }, []);

  const renderDetailPanel = ({ row }) => (
    <Card p="xs" withBorder miw="200%" shadow="md">
      {row.original.orderItems.map((orderItem, index) => (
        <ProductItemLine
          key={orderItem.productItem._id}
          productItem={orderItem.productItem}
          quantity={orderItem.quantity}
          indexInArray={index}
        />
      ))}
    </Card>
  );

  const renderRowActionMenuItems = ({ row }) => {
    return (
      <>
        <Menu.Label>Détails</Menu.Label>
        <Menu.Item
          icon={<IconFileDescription size={14} />}
          onClick={() => navigate(`/customer-orders/${row.original._id}`)}
        >
          Voir détails
        </Menu.Item>
      </>
    );
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Commandes</Text>
      </Flex>

      <Box p={10}>
        <DataTable
          tableColumns={orderColumns}
          tableData={tableData}
          isLoading={isLoading}
          isError={false}
          enableActions={true}
          enableExpanding={true}
          showColumnFilters={false}
          renderRowActionMenuItems={renderRowActionMenuItems}
          renderDetailPanel={renderDetailPanel}
        />
      </Box>
    </AppShell>
  );
};

export default CustomerOrders;
