import { Box, Button, Group, Modal, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { REQUIRED_ERROR } from '../../../../utils/constants';

function CreateStock({ opened, closeModal, submit }) {
  const formValidator = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Ce champ ne peut pas contenir de valeur dont la longueur est inférieur à 3')
      .required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = values => {
    submit(values);
    form.reset();
    closeModal();
  };

  return (
    <Modal
      title={
        <span>
          <h3>Nouveau Stock</h3>
        </span>
      }
      opened={opened}
      onClose={() => closeModal()}
      keepMounted={false}
    >
      <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput label="Nom" placeholder="Stock 1" withAsterisk {...form.getInputProps('name')} />
        <Group position="right" mt="md">
          <Button type="submit">Valider</Button>
        </Group>
      </Box>
    </Modal>
  );
}

CreateStock.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreateStock;
