import { authenticatedRequest, publicRequest } from '../config/axiosConfig';

export const getOrders = async token => {
  try {
    const response = await publicRequest.get(`/orders`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateCustomerAddress = async (token, address, orderId) => {
  try {
    const response = await authenticatedRequest(token).put(`/orders/update-customer-address`, { address, orderId });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const getOrderById = async (token, orderId) => {
  try {
    const response = await authenticatedRequest(token).get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const cancelOrder = async (token, orderId) => {
  try {
    const response = await authenticatedRequest(token).post(`/orders/cancel-order`, { orderId });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const payOrderCredit = async (token, orderId) => {
  try {
    const response = await authenticatedRequest(token).post(`/orders/pay-order-credit`, { orderId });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const finalizeOrder = async (token, orderFinalizationObject) => {
  try {
    const response = await authenticatedRequest(token).post(`/orders/finalize-order`, orderFinalizationObject);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
