export const riderColumns = [
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Nom',
    size: 150,
  },
  {
    accessorKey: 'nickname', //access nested data with dot notation
    header: 'Surnom',
    size: 120,
  },
  {
    accessorKey: 'phone', //access nested data with dot notation
    header: 'Téléphone',
    size: 150,
  },
  {
    accessorKey: 'numberOfDeliveries', //access nested data with dot notation
    header: 'Nombre de courses',
  },
];
