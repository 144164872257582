import { Box, Button, Group, Modal, NumberInput, Select, Text, Textarea, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  ARRAY_NOT_EMPTY_ERROR,
  INVALID_NUMBER_ERROR,
  NAME_MIN_LENGTH_ERROR,
  REQUIRED_ERROR,
  TEXTAREA_MAX_LENGTH_ERROR,
} from '../../../../utils/constants';
import { forwardRef, useEffect, useState } from 'react';
import { getExpenseTypes } from '../../../../services/expense.service';

const SelectItem = forwardRef(({ _id, type, description, category, ...others }, ref) => {
  return (
    <div ref={ref} {...others} key={_id}>
      <Group noWrap m={1} ml={10}>
        <div>
          <Text size="sm">{type}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );
});

function CreateExpense({ opened, closeModal, submit }) {
  const [expenseTypes, setExpenseTypes] = useState([]);

  useEffect(() => {
    const fetchExpenseTypes = async () => {
      try {
        const response = await getExpenseTypes();
        const expenseTypes = response.map(expenseType => ({
          ...expenseType,
          value: expenseType._id,
          label: expenseType.type,
        }));
        setExpenseTypes(expenseTypes);
      } catch (error) {
        console.error(error);
      }
    };
    fetchExpenseTypes();
  }, []);

  const formValidator = Yup.object().shape({
    type: Yup.string().length(24).required(REQUIRED_ERROR),
    description: Yup.string()
      .min(3, NAME_MIN_LENGTH_ERROR)
      .max(1000, TEXTAREA_MAX_LENGTH_ERROR)
      .required(REQUIRED_ERROR),
    amount: Yup.number().min(1).max(1000000000).required(),
  });

  const form = useForm({
    initialValues: {
      type: '',
      description: '',
      amount: 0,
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = values => {
    submit(values);
    form.reset();
    closeModal();
  };

  const handleSelectChange = id => {
    form.setValues({ type: id });
  };

  return (
    <Modal
      title={
        <span>
          <h3>Nouvelle dépense</h3>
        </span>
      }
      opened={opened}
      onClose={() => closeModal()}
      keepMounted={false}
    >
      <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <Select
          mt="md"
          withAsterisk
          withinPortal
          searchable
          clearable
          data={expenseTypes}
          maxDropdownHeight={180}
          placeholder="Choisir un métier"
          label="Métier du prestataire"
          itemComponent={SelectItem}
          value={form.values.type}
          onChange={handleSelectChange}
          error={form.getInputProps('type').error}
          filter={(value, item) => item?.type?.toLowerCase().includes(value?.toLowerCase().trim())}
        />
        <Textarea
          label="Description"
          placeholder="Ajouter la description du produit ici"
          withAsterisk
          autosize
          mt="md"
          minRows={2}
          {...form.getInputProps('description')}
        />
        <NumberInput
          mt="md"
          label="Montant"
          placeholder="10000"
          withAsterisk
          rightSection={
            <Text color="dimmed" size="sm">
              FG
            </Text>
          }
          {...form.getInputProps('amount')}
        />
        <Group position="right" mt="md">
          <Button type="submit">Valider</Button>
        </Group>
      </Box>
    </Modal>
  );
}

CreateExpense.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreateExpense;
