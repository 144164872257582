import {createSlice} from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    darkMode: false,
  },
  reducers: {
    darkModeReducer: (state) => {
      state.darkMode = !state.darkMode
    },
  }
});

export const { darkModeReducer } = settingsSlice.actions;
export default settingsSlice.reducer;