import { BASE_URL } from '../../../../config/axiosConfig';
import { Box, Divider, Flex, Group, Image, Stack, Text } from '@mantine/core';
import { formatCurrency } from '../../../../utils/commons';

const ProductItemLine = ({ productItem, quantity, indexInArray, minWidth }) => {
  const imageUrl = `${BASE_URL}/files/single/${productItem.product.files[0].name}`;
  const totalSellPrice = productItem.sellingPrice * quantity;

  return (
    <Box>
      {indexInArray > 0 && <Divider mt={10} mb={10} variant="dashed" />}
      <Group spacing={0}>
        <Group w="15%">
          {/*<IconTrash color="red" size="1.1rem" />*/}
          <Image height={50} width={50} radius="md" src={imageUrl} />
        </Group>
        <Box w="35%">
          <Stack spacing={0}>
            <Text size="md">{productItem.product.name}</Text>
            <Text color="dimmed">{productItem.sku}</Text>
          </Stack>
        </Box>
        <Box w="10%">
          <Flex h="100%" align="center" justify="center">
            {productItem.size.value}
          </Flex>
        </Box>
        <Box w="10%">
          <Flex h="100%" align="center" justify="center">
            {productItem.color.name}
          </Flex>
        </Box>
        <Box w="10%">
          <Flex h="100%" align="center" justify="center">
            x{quantity}
          </Flex>
        </Box>
        <Box w="20%">
          <Flex h="100%" justify="flex-end">
            {formatCurrency(totalSellPrice)} FG
          </Flex>
        </Box>
      </Group>
    </Box>
  );
};

ProductItemLine.defaultProps = {
  minWidth: 0,
};

export default ProductItemLine;
