import {
  Anchor,
  Button,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { DefaultNavbar } from '../../../layouts/Navbar/DefaultNavbar';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { EMAIL_PATTERN, INVALID_EMAIL_ERROR, REQUIRED_ERROR } from '../../../utils/constants';
import { login } from '../../../services/auth.service';
import { renderNotification } from '../../../utils/commons';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../../redux/auth.slice';
import { useDisclosure } from '@mantine/hooks';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser } = useSelector(state => state.user);

  const [isLoading, { open, close }] = useDisclosure(false);

  const formValidator = Yup.object().shape({
    email: Yup.string().matches(EMAIL_PATTERN, INVALID_EMAIL_ERROR).required(REQUIRED_ERROR),
    password: Yup.string().required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = async values => {
    open();
    try {
      const response = await login(values.email, values.password);
      dispatch(loginAction(response));
      close();
      navigate('/products', { state: { from: 'login' } });
    } catch (error) {
      console.log(error);
      form.setValues({ password: '' });
      close();
      renderNotification('Email ou mot de passe incorrect', 'error');
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div>
      <DefaultNavbar opened={true} />
      <Container size={420} my={100}>
        <Title align="center" sx={theme => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
          Bienvenue
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Vous n'avez pas encore de compte?{' '}
          <Anchor href="/register" size="sm">
            Créer un compte
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput label="Email" placeholder="john.doe@pivot.com" withAsterisk {...form.getInputProps('email')} />
            <PasswordInput
              label="Mot de passe"
              placeholder="Votre mot de passe"
              mt="md"
              withAsterisk
              {...form.getInputProps('password')}
            />
            <Group position="apart" mt="lg">
              <Anchor component="button" size="sm">
                Forgot password?
              </Anchor>
            </Group>
            <Button type="submit" fullWidth mt="xl">
              Se connecter
            </Button>
          </form>
        </Paper>
        <LoadingOverlay visible={isLoading} />
      </Container>
    </div>
  );
};

export default Login;
