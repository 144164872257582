import { Card, createStyles, Group, rem, Text } from '@mantine/core';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons-react';
import { formatCurrency } from '../../../../utils/commons';

const useStyles = createStyles(theme => ({
  card: {
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },

  value: {
    fontSize: rem(22),
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

const StatsCard = ({ title, description, value, Icon, isCurrency = false }) => {
  const { classes } = useStyles();
  // const DiffIcon = diff > 0 ? IconArrowUpRight : IconArrowDownRight;
  return (
    <Card withBorder radius="md" key={title} className={classes.card}>
      <Group position="apart">
        <Text size="xs" color="dimmed" className={classes.title}>
          {title}
        </Text>
        <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
      </Group>

      <Group align="flex-end" spacing="xs" mt={25}>
        <Text className={classes.value}>
          {formatCurrency(value)} {isCurrency ? 'FG' : ''}
        </Text>
        {/*<Text color={diff > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>*/}
        {/*  <span>{diff}%</span>*/}
        {/*  <DiffIcon size="1rem" stroke={1.5} />*/}
        {/*</Text>*/}
      </Group>

      <Text fz="xs" c="dimmed" mt={7}>
        {description}
      </Text>
    </Card>
  );
};

export default StatsCard;
