import { formatCurrency } from '../../utils/commons';
import { Box, Group, Image, Text } from '@mantine/core';
import { BASE_URL } from '../../config/axiosConfig';

export const productColumns = [
  // {
  //   accessorKey: 'id',
  //   header: 'ID',
  //   enableHiding: false,
  //   enableEditing: false,
  // },
  {
    accessorKey: 'sku', //access nested data with dot notation
    header: 'Article',
    enableHiding: false,
    minSize: 220,
    Cell: ({ renderedCellValue, row }) => (
      <Group>
        <Image height={50} width={50} radius="md" src={`${BASE_URL}/files/single/${row.original.images[0].name}`} />
        <Box>
          <Text fw={600}>{row.original.sku}</Text>
          <Text c="dimmed">{row.original.category}</Text>
        </Box>
      </Group>
    ),
  },
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Nom',
    size: 150,
  },
  {
    accessorKey: 'brandName', //access nested data with dot notation
    header: 'Marque',
    size: 100,
  },
  {
    accessorKey: 'size', //access nested data with dot notation
    header: 'Taille',
    size: 90,
  },
  {
    accessorKey: 'color', //access nested data with dot notation
    header: 'Couleur',
    size: 90,
  },
  {
    accessorKey: 'initialQuantity', //access nested data with dot notation
    header: 'Quantité',
    size: 100,
  },
  {
    accessorKey: 'quantityInStock',
    header: 'Stock',
    size: 100,
  },
  {
    accessorKey: 'sellingPrice',
    header: 'Prix',
    size: 120,
    Cell: ({ renderedCellValue, row }) => formatCurrency(row.original.sellingPrice) + ' FG',
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       gap: '16px',
    //     }}
    //   >
    //     <img alt="avatar" height={30} src={row.original.avatar} style={{ borderRadius: '50%' }} />
    //     <span>{renderedCellValue}</span>
    //   </Box>
  },
];
