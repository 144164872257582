import { publicRequest } from '../config/axiosConfig';

const PATH = '/stocks';

export const createStock = async name => {
  try {
    const response = await publicRequest.post(`${PATH}`, { name });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getStocks = async () => {
  try {
    const response = await publicRequest.get(`${PATH}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getStockById = async id => {
  try {
    const response = await publicRequest.get(`${PATH}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
