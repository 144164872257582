import { IconDownload, IconTrash } from '@tabler/icons-react';
import { Image, Indicator } from '@mantine/core';
import PropTypes from 'prop-types';

const FileDisplay = ({ imageUrl, file, isFromServer, isDownloadable, action }) => {
  return (
    <Indicator
      color="transparent"
      label={
        isDownloadable ? (
          <IconDownload color="blue" sx={{ cursor: 'pointer' }} onClick={() => action(file.name, file.originalName)} />
        ) : (
          <IconTrash
            color="red"
            sx={{ cursor: 'pointer' }}
            onClick={() => action(file.name, file.originalName, isFromServer)}
          />
        )
      }
    >
      <Image
        height={120}
        key={file.name}
        radius="md"
        src={imageUrl}
        imageProps={isFromServer ? {} : { onLoad: () => URL.revokeObjectURL(imageUrl) }}
        sx={{
          '&:hover': {
            transform: 'scale(1.03)',
          },
        }}
      />
    </Indicator>
  );
};

FileDisplay.propTypes = {
  file: PropTypes.object.isRequired,
  isFromServer: PropTypes.bool.isRequired,
  isDownloadable: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,

  imageUrl: PropTypes.string,
  Icon: PropTypes.node,
};

export default FileDisplay;
